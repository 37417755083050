/* eslint-disable react/no-unescaped-entities */

import avatar from "./static/img/lisa-avatar.png";
import "./stylesheets/Intro.css";


function Intro() {
    return(
        <div  className="container">
            <div id="intro-banner" className="padded header">
                <div id="intro-left" className="media-col-100">
                    <h1>I am</h1> 
                    <h1>Lisa Martelly</h1>
                </div>
                
            </div>
            <div id="full-intro-section">
                <div id="detailed-intro" className="intro-body padded">
                    <div>
                    <h3>Free tip: Don't make a website if you can't update it.</h3>
    
                    <p>Because once you make a website, you feel stuck with it! Maybe I will update this more later on, 
                        maybe it will continue to haunt me. If you are here, you may want to know about me: I am a software engineer 
                        and I really enjoy it. I like to plan things. As of writing I have one child with another on the way. I like to 
                        walk around my neighborhood and eat donuts. I try not to take life too seriously, could you tell? 
                    </p>
                    </div>
                    
                </div>
            </div>
            
            <div className="photo">
                <div id="intro-right" className="">
                        <img id="avatar" className="" src={avatar} alt="Avatar of Lisa Martelly" />
                        <div id="connection-row" className="icons">
                            <a href="mailto:martelly.lisa@gmail.com" ><i className="bi bi-envelope-fill"></i></a>
                            <a href="https://www.linkedin.com/in/lisa-martelly/" target="_blank" rel="noreferrer"><i className="bi bi-linkedin"></i></a>
                            <a href="https://github.com/lisamartelly" target="_blank" rel="noreferrer"><i className="bi bi-github"></i></a>
                        </div>
                    </div>
                </div>
            <div className="skills padded">
                <div id="skills-box" >
                    <div><h3>SKILLS</h3></div>
                    <div id="skills-sub-box">
                        <ul className="skills-list">
                            <li>React</li>
                            <li>PHP</li>
                            <li>Python</li>
                            <li>Plannning stuff</li>
                            <li>Communication</li>
                            <li>Empathy</li>
                            <li>Not Updating Websites</li>
                            <li>Team Building</li>
                            <li>CSS</li>

                        </ul>
                    </div>
                </div>
            </div>
            
        </div>)
    }

export default Intro;