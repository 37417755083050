

import Conclusion from "./Conclusion";
import Projects from "./Projects";
import Intro from "./Intro";

function Homepage() {

    return(<div>
        
        < Intro />
        {/* < Projects /> */}
        {/* < Conclusion /> */}
    </div>)
}

export default Homepage;